<script setup>
export default {
  name: "Clock",
  data() {
    return {
      showBasic: false,
      setDateClock: false,
      showCountDown: false,
      showSetClock: false,
      showTomatoClock: false,
      showOffScreen: false,
      voiceLink: 'https://www.miaomiaoce.com/manuals/videos/%E9%9F%B3%E9%87%8F.mov',
      table21Data: [
        {
          commands:[ '【设置日期20XX年X月X日】'],
          description: '设备日期会显示当前设定20XX年XX月XX日'
        }, {
          commands: ['【设置时钟XX点XX分】','【设置时钟上午或下午XX点XX分】（12小时制）'],
          description: '设备时钟会显示当前设定时间XX点XX分'
        }
      ],
      table23Data: [
        {
          commands:[ '【打开整点报时】'],
          description: '8:00-18:00期间每到整点设备会报时：现在是XX点整（24小时制）'
        }, {
          commands:[ '【关闭整点报时】'],
          description: '设备不会语音播报时间'
        }
      ],
      table24Data: [
        {
          commands:[ '【现在几点】','【几点了】','【现在什么时间】'],
          description: '仅在8:00-18:00期间可使用语音查看时间，设备会播报当前时间'
        }
      ],
      table32Data: [
        {
          commands:[ '【倒计时XX分钟】'],
          description: '（支持1-60分钟）设备按开始指令倒计时'
        }
      ],
      table33Data: [
        {
          commands:[ '【暂停计时】', '【计时暂停】', '【暂停】'],
          description: '设备倒计时会暂停（该功能只针对60分钟倒计时，天数倒计时不能暂停）'
        }
      ],
      table34Data: [
        {
          commands:[ '【继续计时】', '【计时继续】', '【继续】'],
          description: '设备会继续刚才的倒计时'
        }
      ],
      table35Data: [
        {
          commands:[ '【计时结束】', '【结束计时】', '【取消计时】', '【计时取消】', '【终止计时】', '【计时终止】', '【关闭计时】', '【计时关闭】', '【停止计时】', '【计时停止】'],
          description: '倒计时会停止，设备响铃并彩屏闪烁'
        }
      ],
      table36Data: [
        {
          commands:[ '【倒计时XX天】 '],
          description: '天数倒计时结束时，图标显示000并闪烁。按打断键结束闪烁退出天数倒计时。天数倒计时最大支持  100天 倒计时。'
        },        {
          commands:[ '【取消天数倒计时】'],
          description: '关闭天数倒计时显示'
        }
      ],
      table41Data: [
        {
          commands:[ '【设置闹钟1（上午/下午XX点XX分/XX点整）】（12小时制）', '【设置闹钟1（XX点XX分/XX点整）】（24小时制）'],
          description: '设备会进入语音闹钟，按指令时间设置并打开闹钟1'
        },        {
          commands:[ '【设置闹钟2（上午/下午XX点XX分/XX点整）】（12小时制）', '【设置闹钟2（XX点XX分/XX点整）】（24小时制）'],
          description: '设备会进入语音闹钟，按指令时间设置并打开闹钟2'
        },        {
          commands:[ '【设置闹钟3（上午/下午XX点XX分/XX点整）】（12小时制）', '【设置闹钟3（XX点XX分/XX点整）】', '（24小时制）'],
          description: '设备会进入语音闹钟，按指令时间设置并打开闹钟3'
        }
      ],
      table43Data: [
        {
          commands:[ '【查看闹钟/查看闹钟1】'],
          description: '闹钟1图标及对应设置时间显示（如未设定显示7:00），闪动3秒后，恢复原状'
        }, {
          commands:[ '【查看闹钟2】'],
          description: '闹钟2图标及对应设置时间显示（如未设定显示7:00），闪动3秒后，恢复原状'
        }, {
          commands:[ '【查看闹钟3】'],
          description: '闹钟3图标及对应设置时间显示（如未设定显示7:00），闪动3秒后，恢复原状'
        }
      ],
      table44Data: [
        {
          commands:[ '【打开闹钟1】', '【开启闹钟1】'],
          description: '闹钟1会打开'
        }, {
          commands:[ '【关闭闹钟1】', '【闹钟1关闭】'],
          description: '闹钟1会关闭'
        },
        {
          commands:[ '【打开闹钟2】', '【开启闹钟2】'],
          description: '闹钟2会打开'
        }, {
          commands:[ '【关闭闹钟2】', '【闹钟2关闭】'],
          description: '闹钟2会关闭'
        },
        {
          commands:[ '【打开闹钟3】', '【开启闹钟3】'],
          description: '闹钟3会打开'
        }, {
          commands:[ '【关闭闹钟3】', '【闹钟3关闭】'],
          description: '闹钟3会关闭'
        },
        {
          commands:[ '【打开闹钟1闹钟2】', '【开启闹钟1闹钟2】'],
          description: '闹钟1闹钟2会打开'
        },
        {
          commands:[ '【关闭闹钟1闹钟2】', '【闹钟1闹钟2关闭】'],
          description: '闹钟1闹钟2会关闭'
        },
        {
          commands:[ '【打开闹钟2闹钟3】', '【开启闹钟2闹钟3】'],
          description: '闹钟2闹钟3会打开'
        },
        {
          commands:[ '【关闭闹钟2闹钟3】', '【闹钟2闹钟3关闭】'],
          description: '闹钟2闹钟3会关闭'
        },{
          commands:[ '【打开闹钟1闹钟3】', '【开启闹钟1闹钟3】'],
          description: '闹钟1闹钟3会打开'
        },
        {
          commands:[ '【关闭闹钟1闹钟3】', '【闹钟1闹钟3关闭】'],
          description: '闹钟1闹钟3会关闭'
        },
        {
          commands:[ '【打开所有闹钟】', '【开启所有闹钟】'],
          description: '闹钟会全部打开'
        },
        {
          commands:[ '【关闭所有闹钟】', '【所有闹钟关闭】'],
          description: '闹钟会全部关闭'
        },
      ],
      table45Data: [
        {
          commands:[ '【设置闹钟铃声1】', '【闹钟铃声1】', '【闹铃1】'],
          description: '闹钟铃声设置为闹铃1'
        }, {
          commands:[ '【设置闹钟铃声2】', '【闹钟铃声2】', '【闹铃2】'],
          description: '闹钟铃声设置为闹铃2'
        }
      ],
      table46Data: [
        {commands:['【所有闹钟单次模式】'],description:'所有闹钟为单次响铃模式，不循环'},
        {commands:['【闹钟1单次模式】'],description:'闹钟1为单次响铃模式，不循环'},
        {commands:['【闹钟2单次模式】'],description:'闹钟2为单次响铃模式，不循环'},
        {commands:['【闹钟3单次模式】'],description:'闹钟3为单次响铃模式，不循环'},
        {commands:['【所有闹钟五天循环】','【所有闹钟工作日循环】'],description:'所有闹钟为周一~周五循环模式'},
        {commands:['【闹钟1五天循环】','【闹钟1工作日循环】'],description:'闹钟1为周一~周五循环模式'},
        {commands:['【闹钟2五天循环】','【闹钟2工作日循环】'],description:'闹钟2为周一~周五循环模式'},
        {commands:['【闹钟3五天循环】','【闹钟3工作日循环】'],description:'闹钟3为周一~周五循环模式'},
        {commands:['【所有闹钟六天循环】'],description:'所有闹钟为周一~周六循环模式'},
        {commands:['【闹钟1六天循环】'],description:'闹钟1为周一~周六循环模式'},
        {commands:['【闹钟2六天循环】'],description:'闹钟2为周一~周六循环模式'},
        {commands:['【闹钟3六天循环】'],description:'闹钟3为周一~周六循环模式'},
        {commands:['【所有闹钟七天循环】'],description:'所有闹钟为周一~周日循环模式'},
        {commands:['【闹钟1七天循环】'],description:'闹钟1为周一~周日循环模式'},
        {commands:['【闹钟2七天循环】'],description:'闹钟2为周一~周日循环模式'},
        {commands:['【闹钟3七天循环】'],description:'闹钟3为周一~周日循环模式'}
      ],
      table47Data: [
        {commands:['【再睡一会儿】','【稍后提醒】'],description:'闹钟响起后，单击闹钟按键或直接说“再睡一会儿”闹钟停止响铃并进入贪睡模式（默认3次贪睡，每次间隔10分钟）'},
        {commands:['【别响了】','【别叫了】','【别吵了】'],description:'闹闹钟响起后，长按语音按键2秒，听到系统语音：“闹钟已结束”或直接说“别响了”此闹钟关闭不再响起'}
      ],
      table51Data: [
        {commands:['【开始番茄计时】','【打开番茄计时】'],description:'设备自动开始25分钟倒计时'},
        {commands:['【结束番茄计时】','【关闭番茄计时】'],description:'设备会停止番茄倒计时'}
      ],
    // eslint-disable-next-line no-unused-vars
      setDataClockSpan({row, column, rowIndex, columnIndex}) {
        if (columnIndex === 1) {
          if (rowIndex === 1) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else if (rowIndex === 2) {
            return {
              rowspan: 0,
              colspan: 0
            };
          } else {
            return {
              rowspan: 1,
              colspan: 1
            };
          }
        }
      },
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",
          src: 'http://www.html5videoplayer.net/videos/madagascar3.mp4'//url地址
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      }
    }
  },
  methods: {
    changeBasic() {
      this.showBasic = !this.showBasic
    },
    changeDateClock() {
      this.setDateClock = !this.setDateClock
    },
    changeCountDown() {
      this.showCountDown = !this.showCountDown
    },
    changeSetClock() {
      this.showSetClock = !this.showSetClock
    },
    changeTomatoClock() {
      this.showTomatoClock = !this.showTomatoClock
    },
    changeOffScreen() {
      this.showOffScreen = !this.showOffScreen
    }
  }


}
</script>

<template>
  <div class="body">
      <img :src="require('../../assets/clock/arrow.png')" :width="30" :height="30" style="margin-top: 33pt">
    <div style="; padding-left: 9%; padding-right: 9%; margin-bottom: 32pt;">
      <img :src="require('../../assets/clock/clock.png')" width="90%">
      <p style="text-align: left; font-size: 14px;">
        单击设备顶部唤醒/打断键，听到“嘀”声后对设备发布下方语音命令指令
      </p>
    </div>
    <div class="videoTitle" @click="changeBasic()">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-1.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>
      </div>
      <div class="videoCard_text">基础功能设定</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="showBasic" class="videoCard_info">
      <p>
        <span class="videoCard_issue">电源键：</span>
        长按电源键2秒开机,显示屏亮屏并播放提示音，如需关机，同样长按电源键2秒即可。
      </p>
      <p>
        <span class="videoCard_issue">时钟键：</span> 用于手动设定时钟时使用。
      </p>
      <p>
        <span class="videoCard_issue">闹钟键：</span> 用于手动设定及选择闹钟使用。
      </p>
      <p>
        <span class="videoCard_issue">唤醒/打断键：</span> 用于语音唤醒功能或铃声响起时打断停止。
      </p>
      <p>
        <span class="videoCard_issue">音量键：</span> 可切换设备音量大小，音量共分为大声、小声、静音3档。
      </p>
      <p>
        <span class="videoCard_issue">拨动滚轮：</span> 用于调节选择倒计时或时钟日期数字等。
        星期下面红色点标识当天星期几；星期上面白色点标识为闹钟响铃循环的天数。倒计时彩色线条显示时间，中间数字为正计时显示。
      </p>
      <div class="video-container">
        <video width="240" height="135" style="text-align: center" poster="../../assets/clock/【视频1-1】.png" controls="">
          <source src="../../assets/clock/video/01基础功能设定/01按键说明.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>
      <img :src="require('../../assets/clock/base.jpg')" style="width: 100% ;clear:both;display:block;margin:auto">

      <div class="video-container">
        <video width="240" height="135" style="text-align: center" poster="../../assets/clock/【视频1-2】.png" controls="">
          <source src="../../assets/clock/video/01基础功能设定/02屏幕显示说明.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
      </div>

    </div>

    <div class="videoTitle" @click="changeDateClock()">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-2.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>
      </div>
      <div class="videoCard_text">日期、时钟设置</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="setDateClock" class="videoCard_info">
      <h3>【如何设置日期、时钟】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>
      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table21Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>
      <video width="240" height="135" :poster="require('../../assets/clock/【视频2-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/02如何设置日期时钟/01语音设置日期时钟.mp4" type="video/mp4"/>
      </video>

      <h3 style="margin-top: 10px">【按键设置日期、时间】</h3>
      <p>
        长按设备背面时钟键进入年份设置，年份数字闪烁（前两位固定为20），转动滚轮调整数值，短按时钟键确定后进入月份设置。
      </p>
      <p>转动滚轮调整数值，再短按时钟键确认进入日期设置，</p>
      <p>转动滚轮调整数值，短按时钟键确认后进入小时设置，</p>
      <p>转动滚轮调整数值，短按时钟键确认后进入分钟设置，</p>
      <p>转动滚轮调整数值，最后短按时钟键完成所有设置。</p>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频2-2】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/02如何设置日期时钟/02按键设置日期时钟.mp4" type="video/mp4"/>
      </video>

      <h3 style="margin-top: 10px">【如何打开整点报时】</h3>
      <p>单击唤醒/打断键，听到“嘀”声后说出如下命令词：</p>
      <el-table :data="table23Data"  border style="margin: 10px 10px"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
        <el-table-column prop="description" label="说明"></el-table-column>
      </el-table>
      <video width="240" height="135" :poster="require('../../assets/clock/video/newpic/整点报时功能.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/02如何设置日期时钟/03整点报时功能0805.mp4" type="video/mp4"/>
      </video>

      <h3 style="margin-top: 10px">【如何查询时间】</h3>
      <p>单击唤醒/打断键，听到“嘀”声后说出如下命令词：</p>
      <el-table :data="table24Data" border style="margin: 10px 10px"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column prop="command" label="命令词">
          <template slot-scope="scope">
            <div v-for="(command, index) in scope.row.commands" :key="index">
              {{ command }}<br>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="说明"></el-table-column>
      </el-table>
      <video width="240" height="135" :poster="require('../../assets/clock/video/newpic/如何查询时间.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/05如何查询时间.mp4" type="video/mp4"/>
      </video>
    </div>

    <div class="videoTitle" @click="changeCountDown()">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-3.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>

      </div>
      <div class="videoCard_text">倒计时</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="showCountDown" class="videoCard_info">
      <h3>【滚轮设置倒计时】</h3>
      <p>待机状态下，转动滚轮，顺时针转动增加时间，逆时针转动减少时间。</p>
      <p>停止操作后倒计时开始。色环内数字显示正计时，色环显示倒计时。</p>
      <p>每一格代表1分钟。待机状态下色环转动表示时钟秒针。</p>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频3-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/01滚轮设定倒计时.mp4" type="video/mp4"/>
      </video>

      <h3>【语音设置倒计时】</h3>
      <p>如何设置倒计时？单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>
      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table32Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/video/newpic/语音设定倒计时.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/02语音设定倒计时.mp4" type="video/mp4"/>
      </video>

      <h3>【如何累加计时】</h3>
      <img :src="require('../../assets/clock/video/累加计时模块.png')"
           style="width: 100% ;clear:both;display:block;margin:auto"/>
      <video width="240" height="135" :poster="require('../../assets/clock/video/newpic/累加计时模块.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/04累加计时模块.mp4" type="video/mp4"/>
      </video>

      <h3>【如何暂停倒计时】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>



      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table33Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频3-3】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/03暂停计时.mp4" type="video/mp4"/>
      </video>

      <h3>【如何继续倒计时】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table34Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频3-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/04继续计时.mp4" type="video/mp4"/>
      </video>

      <h3>【如何结束倒计时】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table35Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频3-5】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/05结束计时.mp4" type="video/mp4"/>
      </video>

      <h3>【如何设置天数倒计时】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table36Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频3-6】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/03倒计时设置/06天数倒计时.mp4" type="video/mp4"/>
      </video>

    </div>

    <div class="videoTitle" @click="changeSetClock()">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-4.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>
      </div>
      <div class="videoCard_text">闹钟设置</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="showSetClock" class="videoCard_info">
      <h3>【语音设置闹钟】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table41Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/01语音设置闹钟.mp4" type="video/mp4"/>
      </video>

      <h3>【按键设置闹钟】</h3>
      <p>长按设备顶部闹钟键进入闹钟设定模式，闹钟1图标闪烁，转动滚轮选择数值确认小时，再短按闹钟键进入分钟设置，依次设置三个闹钟设定即可。</p>
      <p>短按闹钟键切换三组闹钟的不同组合显示方式。</p>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-2】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/02按键设置闹钟.mp4" type="video/mp4"/>
      </video>

      <h3>【如何查看闹钟】</h3>
      <p>单击唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table43Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-3】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/03语音查看闹钟.mp4" type="video/mp4"/>
      </video>

      <h3>【如何打开/关闭闹钟】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table44Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-4】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/04语音打开关闭闹钟.mp4" type="video/mp4"/>
      </video>

      <h3>【如何选择闹钟铃声】</h3>
      <p>单击设备顶部唤醒键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table45Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-5】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/05闹铃选择.mp4" type="video/mp4"/>
      </video>

      <h3>【如何设置闹钟循环天数】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table46Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-6】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/06闹钟循环设置.mp4" type="video/mp4"/>
      </video>

      <h3>【如何关闭响铃】</h3>
      <p>闹钟响起后长按唤醒/打断键3秒直接关闭闹钟，也可单击唤醒/打断键或直接说：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table47Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频4-7】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/04闹钟设置/07如何关闭闹钟铃声.mp4" type="video/mp4"/>
      </video>
    </div>

    <div class="videoTitle" @click="changeTomatoClock()">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-5.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>
      </div>
      <div class="videoCard_text">番茄计时</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="showTomatoClock" class="videoCard_info">
      <h3>【如何开始/关闭番茄计时】</h3>
      <p>单击设备顶部唤醒/打断键，听到“嘀”声后说出如下命令词：</p>

      <div style="clear:both;display:block;margin:auto">

        <el-table :data="table51Data"  border style="margin: 10px 10px"
                  :header-cell-style="{'text-align':'center'}">
          <el-table-column prop="command" label="命令词">
            <template slot-scope="scope">
              <div v-for="(command, index) in scope.row.commands" :key="index">
                {{ command }}<br>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="说明"></el-table-column>
        </el-table>
      </div>

      <video width="240" height="135" :poster="require('../../assets/clock/【视频5-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/05番茄计时/番茄计时.mp4" type="video/mp4"/>
      </video>
    </div>

    <div class="videoTitle" @click="changeOffScreen()" style="margin-bottom: 5px">
      <div class="videoCard_image">
        <img :src="require('../../assets/clock/电子书icon-6.png')"
             style="width: 28px;height: 28px;border-radius: 14px"/>
      </div>
      <div class="videoCard_text">关屏功能</div>
      <div class="videoCard_icon"></div>
    </div>
    <div v-if="showOffScreen" class="videoCard_info" style="margin-bottom: 30px">
      <p>为不影响用户睡眠，本产品有夜间自动息屏功能，在晚上23:00-凌晨6：00会自动关闭显示，同时用户也可在任意时间通过语音主动关闭屏幕显示。</p>
      <p>如何关闭显示？单击设备顶部唤醒/打断键，听到“嘀”声后说：【关闭显示】设备关闭显示。</p>
      <p>关闭显示状态下，按任意按键或滚轮即可打开屏幕显示。</p>


      <video width="240" height="135" :poster="require('../../assets/clock/【视频6-1】.png')" controls
             style="clear:both;display:block;margin: 50px auto;">
        <source src="../../assets/clock/video/06关屏功能/关屏功能.mp4" type="video/mp4"/>
      </video>
    </div>


  </div>
</template>

<style scoped>
.body {
  margin: 0 auto;
  max-width: 428px;
  background-color: #ffffff;
  color: #000;
  text-align: center;
}

.videoTitle {
  align-items: center;
  background-color: #dde4f5;
  border-radius: 20px;
  display: flex;
  height: 60pt;
  justify-content: center;
  margin-left: 3%;
  margin-top: 5pt;
  width: 94%;
}

.videoCard_image {
  margin-left: 15px;
  margin-right: 10px;
  text-align: left;
}

.videoCard_text {
  color: #5486e7;
  font-size: 17px;
  text-align: left;
  width: 300px;
}

.videoCard_icon {
  color: #5486e7;
  font-size: 20pt;
  margin-right: 20px;
}

.videoCard_info {
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoCard_issue {
  font-weight: 700
}

.el-table__header {
  border-collapse: inherit;
}

.el-table .cell {
  white-space: pre-line;
}
.el-table {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  margin: 0 auto;
}

::v-deep.el-table th {
  border: 1px solid black !important;
  border-right: none !important;
  border-bottom: none !important;
}

::v-deep.el-table td {
  border: 1px solid black;
  border-right: none !important;
}

video{
  margin-bottom: 30px;
  margin-top: 30px;
}

</style>